export const doctors = [
    {
    name: 'M. Grabowska', 
    specializations: [{
      name: 'Poradnia ogólna',
      workingDays: ['Wtorek: od 08:00']
    }]
  },
  {
    name: 'B. Nogala', 
    specializations: [{
      name: 'Poradnia ogólna',
      workingDays: ['Piątek: od 08:00']
    }]
  },
  {
    name: 'M. Mazur-Popińska', 
    specializations: [{
      name: 'Poradnia dziecięca',
      workingDays: ['Poniedziałek: od 08:00']
    }]
  },
  {
    name: 'A. Kubas', 
    specializations: [{
      name: 'Poradnia ogólna',
      workingDays: ['Środa: od 8:00']
    }]
  },
 
]